import { TagCompanyOption, TagMenuOption, TagMenuOptionMenus, Tags } from '../types'
import { ServiceDTO } from '../../../internal-communication'
import { ChannelMenuType } from '../../../bff/admin/ChannelConfiguration.communication'

export function mapByIdentifier(tags: Tags) {
    const identifierMap = new Map<string, Tags>()
    identifierMap.set(tags.identifier, tags)
    return identifierMap
}

export function mergeWithMappedTags(tagMap: Map<string, Tags>) {
    return (tag: Tags) => {
        const foundMappedTag = tagMap.get(tag.identifier)
        if (foundMappedTag) {
            const newMenu = foundMappedTag.menu || []
            const currentMenus = tag.menu || []
            const merged = { ...tag, ...foundMappedTag }
            merged.menu = [...currentMenus, ...newMenu]
            return merged
        }
        return tag
    }
}

export function tagNotIn(identifierToRemove: string) {
    return ({ identifier }: Tags) => identifier !== identifierToRemove
}

export function findTagByIdentifier(identifierToFind: string) {
    return ({ identifier }: Tags) => identifier === identifierToFind
}

export const toTagMenuOptions = (tagCompanies: TagCompanyOption[], dbServices: ServiceDTO[]) => {
    const menusMap = new Map<string, TagMenuOptionMenus[]>()
    const tagMenus: TagMenuOption[] = []

    for (const service of dbServices) {
        if (service.isSubService === true) continue

        const menu: TagMenuOptionMenus = {
            id: service._id.toString(),
            description: service.description || '',
            type: service.menuType || ChannelMenuType.UNKNOWN,
            childrenServices: getChildrens(service, dbServices)
        }

        const menuMap = menusMap.get(service.companyId)
        if (menuMap) {
            menuMap.push(menu)
        } else {
            menusMap.set(service.companyId, [menu])
        }
    }

    for (const [companyId, menus] of menusMap.entries()) {
        const menuOption: TagMenuOption = {
            companyId,
            companyName: tagCompanies.find((company) => company._id === companyId)?.name || '',
            menus
        }

        tagMenus.push(menuOption)
    }

    return tagMenus
}

const getChildrens = (service: ServiceDTO, dbServices: ServiceDTO[]) => {
    const childrens: TagMenuOptionMenus[] = []

    if (service.menuType !== ChannelMenuType.SUB_MENU) return childrens
    if (!service.listSubService?.length) return childrens

    for (const subServiceId of service.listSubService) {
        const foundSubService = dbServices.find((s) => s._id.toString() === subServiceId)
        if (foundSubService) {
            const children: TagMenuOptionMenus[] = getChildrens(foundSubService, dbServices)

            childrens.push({
                id: foundSubService._id.toString(),
                description: foundSubService.description || '',
                type: foundSubService.menuType || ChannelMenuType.SUB_MENU,
                childrenServices: children
            })
        }
    }

    return childrens
}
