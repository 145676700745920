import { Readable } from 'stream'
import FormData from 'form-data'
import { HttpError, isHttpError, Logger, LoggerLike } from '@syonet/lang'
import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios'
import { EurekaServiceDTO } from '../../communication'
import { CaughtException } from '../common'

type Format = 'JSON' | 'FORM-DATA'

type SendParams<REQ> = { url: () => string; request?: REQ; method?: string; format?: Format }

const DEFAULT_LOGGER = new Logger('BffRestClient')

// Default to 10 minutes
const DEFAULT_TIMEOUT = +(process.env.BFF_TIMEOUT || 10 * 60 * 1000)

export class BffRestClient {
    // :: Statics

    private static readonly INSTANCE = new BffRestClient()

    public static singleton() {
        return BffRestClient.INSTANCE
    }

    private constructor() {
        // Only singleton instance allowed
    }

    // :: Instance

    private __logger: LoggerLike = DEFAULT_LOGGER

    public set logger(logger: LoggerLike) {
        this.__logger = logger ?? DEFAULT_LOGGER
    }

    private __timeout = DEFAULT_TIMEOUT

    public get timeout() {
        return this.__timeout
    }

    public set timeout(value: number) {
        this.__timeout = value
    }

    private __useInternalHosts = true

    public get useInternalHosts() {
        return this.__useInternalHosts
    }

    public set useInternalHosts(value: boolean) {
        this.__useInternalHosts = value
    }

    private __secret?: string

    public get secret() {
        return this.__secret
    }

    public set secret(value: string | undefined) {
        this.__secret = value
    }

    private __eurekaEndpoint = 'http://localhost:3093'

    public get eurekaEndpoint(): string {
        return this.__eurekaEndpoint
    }

    public set eurekaEndpoint(value: string) {
        this.__eurekaEndpoint = value
    }

    private __infobipMiddlewareEndPoint = 'http://localhost:3097'

    public get infobipMiddlewareEndPoint(): string {
        return this.__infobipMiddlewareEndPoint
    }

    private __chatLoaderEndPoint = 'http://localhost:3095'

    private get chatLoaderEndPoint(): string {
        return this.__chatLoaderEndPoint
    }

    private __syonetMiddlewareEndPoint = 'http://localhost:3092'

    public get syonetMiddlewareEndPoint(): string {
        return this.__syonetMiddlewareEndPoint
    }

    private __serverMessengerEndPoint = 'http://localhost:3094'

    public get serverMessengerEndPoint(): string {
        return this.__serverMessengerEndPoint
    }

    private __syoReportsEndPoint = 'http://localhost:3090'

    public get syoReportsEndPoint(): string {
        return this.__syoReportsEndPoint
    }

    private __syoBotAliceEndPoint = 'http://localhost:3098'

    public get syoBotAliceEndPoint(): string {
        return this.__syoBotAliceEndPoint
    }

    private __syoAlertEndPoint = 'http://localhost:3102'

    public get syoAlertEndPoint(): string {
        return this.__syoAlertEndPoint
    }

    private __taskExecutorEndPoint = 'http://localhost:3100'

    public get taskExecutorEndPoint(): string {
        return this.__taskExecutorEndPoint
    }

    private __roomManagerEndPoint = 'http://localhost:3091'

    public get roomManagerEndPoint(): string {
        return this.__roomManagerEndPoint
    }

    private __serverNotificationEndPoint = 'http://localhost:3070'

    public get serverNotificationEndPoint(): string {
        return this.__serverNotificationEndPoint
    }

    private __whatsappBusEndPoint = 'http://localhost:3072'

    public get whatsappBusEndPoint(): string {
        return this.__whatsappBusEndPoint
    }

    private __monitorEndPoint = 'http://localhost:3071'

    public get monitorEndPoint(): string {
        return this.__monitorEndPoint
    }

    private __batchHSMEndPoint = 'http://localhost:3101'

    public get batchHSMEndPoint(): string {
        return this.__batchHSMEndPoint
    }

    private __webhookExecutorEndPoint = 'http://localhost:3103'

    public get webhookExecutorEndPoint(): string {
        return this.__webhookExecutorEndPoint
    }

    public async refreshEndpoints() {
        try {
            const result = await axios.get<EurekaServiceDTO[]>(`${this.eurekaEndpoint}/registry`)
            if (result.status !== 200) {
                throw new Error(`${result.status}: ${result.statusText}`)
            }

            this.updateEndpointFromEureka(result.data)
        } catch (caught) {
            console.error(caught)
        }
    }

    public async syonet<REQ, RSP>(topic: string, request?: REQ, method: string = 'POST') {
        const url = () => `${this.syonetMiddlewareEndPoint}/bff/${topic}`
        return await this.send<REQ, RSP>({ url, request, method })
    }

    public async infobip<REQ, RSP>(topic: string, request: REQ) {
        const url = () => `${this.infobipMiddlewareEndPoint}/bff/${topic}`
        return await this.send<REQ, RSP>({ url, request })
    }

    public async chatLoader<REQ, RSP>(topic: string, request: REQ) {
        const url = () => `${this.chatLoaderEndPoint}/bff/${topic}`
        return await this.send<REQ, RSP>({ url, request })
    }

    public async messenger<REQ, RSP>(topic: string, request: REQ, format?: Format, method?: string) {
        const url = () => `${this.serverMessengerEndPoint}/bff/${topic}`
        return await this.send<REQ, RSP>({ url, request, format, method })
    }

    public async reports<REQ, RSP>(topic: string, request?: REQ, method?: string, format?: Format) {
        const url = () => `${this.syoReportsEndPoint}/bff/${topic}`
        return await this.send<REQ, RSP>({ url, request, method, format })
    }

    public async room<REQ, RSP>(topic: string, request: REQ) {
        const url = () => `${this.roomManagerEndPoint}/bff/${topic}`
        return await this.send<REQ, RSP>({ url, request })
    }

    public async notification<REQ, RSP>(topic: string, request: REQ) {
        const url = () => `${this.serverNotificationEndPoint}/bff/${topic}`
        return await this.send<REQ, RSP>({ url, request })
    }

    public async alice<REQ, RSP>(topic: string, request: REQ, method?: string) {
        const url = () => `${this.syoBotAliceEndPoint}/bff/${topic}`
        return await this.send<REQ, RSP>({ url, request, method })
    }

    public async syoAlert<REQ, RSP>(topic: string, request: REQ) {
        const url = () => `${this.syoAlertEndPoint}/bff/${topic}`
        return await this.send<REQ, RSP>({ url, request })
    }
    public async taskExecutor<REQ, RSP>(topic: string, request: REQ) {
        const url = () => `${this.taskExecutorEndPoint}/bff/${topic}`
        return await this.send<REQ, RSP>({ url, request })
    }

    public async monitor<REQ, RSP>(topic: string, request: REQ) {
        const url = () => `${this.monitorEndPoint}/bff/${topic}`
        return await this.send<REQ, RSP>({ url, request })
    }

    public async batchHSM<REQ, RSP>(topic: string, request: REQ) {
        const url = () => `${this.batchHSMEndPoint}/bff/${topic}`
        return await this.send<REQ, RSP>({ url, request })
    }

    public async webhookExecutor<REQ, RSP>(topic: string, request: REQ, method?: string) {
        const url = () => `${this.webhookExecutorEndPoint}/bff/${topic}`
        return await this.send<REQ, RSP>({ url, request, method })
    }

    private async internalSend(url: () => string, data?: unknown, method: string = 'POST') {
        let restResponse: AxiosResponse | undefined = undefined

        let attempCount = 0
        while (attempCount < 2) {
            try {
                attempCount++

                const headers: AxiosRequestHeaders = {
                    'Content-Type': 'application/json; charset=utf-8'
                }

                if (this.__secret) {
                    headers['Authorization'] = this.__secret
                }

                restResponse = await axios({
                    method,
                    timeout: this.__timeout,
                    url: url(),
                    headers,
                    data,
                    maxBodyLength: Infinity
                })

                return restResponse
            } catch (caught: unknown) {
                const exn = caught as Record<string, unknown>
                if (exn.response) {
                    const response = exn.response as AxiosResponse
                    if (response.status === 404 && attempCount === 1) {
                        await this.refreshEndpoints()
                        continue
                    }
                    return exn.response as AxiosResponse
                } else {
                    if (exn.errno === -111 && attempCount === 1) {
                        await this.refreshEndpoints()
                        continue
                    } else {
                        return undefined
                    }
                }
            }
        }
    }
    private async send<REQ, RSP>(params: SendParams<REQ>) {
        const { url, request, method = 'POST', format = 'JSON' } = params

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let data
        if (method === 'POST') {
            const handleFormat = (format: Format) => (data: any) =>
                format === 'FORM-DATA' && typeof data === 'object'
                    ? Object.keys(data).reduce((form, key) => {
                          const value = data[key]

                          typeof value === 'object' &&
                          'mimetype' in value &&
                          'originalname' in value &&
                          'buffer' in value
                              ? form.append(key, Readable.from(value.buffer), {
                                    filename: value.originalname,
                                    contentType: value.mimetype
                                })
                              : form.append(key, value)

                          return form
                      }, new FormData())
                    : format === 'JSON'
                    ? JSON.stringify(request)
                    : request

            const formatParser = handleFormat(format)
            data = formatParser(request)
        }

        const restResponse = await this.internalSend(url, data, method)
        if (!restResponse) {
            throw new HttpError(421, `Endpoint ${url()} is not reachable`)
        }

        if (isHttpError(restResponse.status)) {
            throw new HttpError(restResponse.status, `${restResponse.statusText}. URL: ${url()}`)
        }

        if (!restResponse.data && isHttpError(restResponse.status)) {
            const message = `Not Found from endpoint ${url()}`
            this.__logger.error(message)
            throw new HttpError(404, message)
        }

        const responseError = restResponse.data as CaughtException
        if (responseError.err && responseError.err !== 200 && responseError.err !== 201) {
            this.__logger.error({ targetUrl: url(), error: responseError.err })
            throw new HttpError(responseError.err, responseError.msg ?? 'Unexpected')
        }

        return restResponse.data as RSP
    }

    private updateEndpointFromEureka(endpointArray: EurekaServiceDTO[]) {
        const map = new Map<string, EurekaServiceDTO>()

        for (const endpoint of endpointArray) {
            map.set(endpoint.name, endpoint)
        }

        let endpoint: EurekaServiceDTO | undefined

        endpoint = map.get('middleware-infobip')
        if (endpoint) {
            this.__infobipMiddlewareEndPoint = makeUrl(endpoint, this.__useInternalHosts, 3097)
            this.__logger.log(`${endpoint.name}: ${this.__infobipMiddlewareEndPoint}`)
        }

        endpoint = map.get('chat-loader')
        if (endpoint) {
            this.__chatLoaderEndPoint = makeUrl(endpoint, this.__useInternalHosts, 3095)
            this.__logger.log(`${endpoint.name}: ${this.__chatLoaderEndPoint}`)
        }

        endpoint = map.get('middleware-syonet')
        if (endpoint) {
            this.__syonetMiddlewareEndPoint = makeUrl(endpoint, this.__useInternalHosts, 3092)
            this.__logger.log(`${endpoint.name}: ${this.__syonetMiddlewareEndPoint}`)
        }

        endpoint = map.get('server-messenger')
        if (endpoint) {
            this.__serverMessengerEndPoint = makeUrl(endpoint, this.__useInternalHosts, 3094)
            this.__logger.log(`${endpoint.name}: ${this.__serverMessengerEndPoint}`)
        }

        endpoint = map.get('syo-reports')
        if (endpoint) {
            this.__syoReportsEndPoint = makeUrl(endpoint, this.__useInternalHosts, 3090)
        }

        endpoint = map.get('syo-bot-alice')
        if (endpoint) {
            this.__syoBotAliceEndPoint = makeUrl(endpoint, this.__useInternalHosts, 3098)
            this.__logger.log(`${endpoint.name}: ${this.__syoBotAliceEndPoint}`)
        }

        endpoint = map.get('syo-alert')
        if (endpoint) {
            this.__syoAlertEndPoint = makeUrl(endpoint, this.__useInternalHosts, 3102)
            this.__logger.log(`${endpoint.name}: ${this.__syoAlertEndPoint}`)
        }

        endpoint = map.get('task-executor')
        if (endpoint) {
            this.__taskExecutorEndPoint = makeUrl(endpoint, this.__useInternalHosts, 3100)
            this.__logger.log(`${endpoint.name}: ${this.__taskExecutorEndPoint}`)
        }

        endpoint = map.get('room-manager')
        if (endpoint) {
            this.__roomManagerEndPoint = makeUrl(endpoint, this.__useInternalHosts, 3091)
            this.__logger.log(`${endpoint.name}: ${this.__roomManagerEndPoint}`)
        }

        endpoint = map.get('syo-whats-app-bus')
        if (endpoint) {
            this.__whatsappBusEndPoint = makeUrl(endpoint, this.__useInternalHosts, 3072)
            this.__logger.log(`${endpoint.name}: ${this.__whatsappBusEndPoint}`)
        }

        endpoint = map.get('monitor')
        if (endpoint) {
            this.__monitorEndPoint = makeUrl(endpoint, this.__useInternalHosts, 3071)
            this.__logger.log(`${endpoint.name}: ${this.__monitorEndPoint}`)
        }

        endpoint = map.get('batch-message-processor')
        if (endpoint) {
            this.__batchHSMEndPoint = makeUrl(endpoint, this.__useInternalHosts, 3101)
            this.__logger.log(`${endpoint.name}: ${this.__batchHSMEndPoint}`)
        }

        endpoint = map.get('webhook-executor')
        if (endpoint) {
            this.__webhookExecutorEndPoint = makeUrl(endpoint, this.__useInternalHosts, 3103)
            this.__logger.log(`${endpoint.name}: ${this.__webhookExecutorEndPoint}`)
        }
    }
}

const makeUrl = (endpoint: EurekaServiceDTO, internal: boolean, defaultPort: number) => {
    if (internal) {
        if (endpoint.internalPort == '80') {
            return `http://${endpoint.internalIp}`
        } else if (endpoint.internalPort == '443') {
            return `https://${endpoint.internalIp}`
        } else if (endpoint.internalPort) {
            return `http://${endpoint.internalIp}:${endpoint.internalPort}`
        } else {
            return `http://${endpoint.internalIp}`
        }
    } else if (endpoint.connectionUrl) {
        return endpoint.connectionUrl
    } else {
        if (defaultPort === 80) {
            return 'http://localhost'
        } else if (defaultPort == 443) {
            return 'https://localhost'
        } else if (defaultPort) {
            return `http://localhost:${defaultPort}`
        } else {
            return 'http://localhost'
        }
    }
}
